'use client';

import React, { ReactNode, useEffect, useState } from 'react';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

declare global {
  interface Window {
    __queryClient?: QueryClient;
  }
}

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import('@tanstack/react-query-devtools/build/modern/production.js').then((d) => ({
    default: d.ReactQueryDevtools,
  })),
);

export const getQueryClient = () => {
  return window.__queryClient;
};

export default function QueryProviderSsr({ children }: { children: ReactNode }) {
  const [showDevtools, setShowDevtools] = useState(() => {
    return ['local', 'develop', 'feature'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT || '');
  });
  const [client] = useState(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 10 * (60 * 1000),
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          retry: false,
          retryOnMount: false,
          retryDelay: 0,
        },
      },
    });
  });

  useEffect(() => {
    //eslint-disable-next-line
    // @ts-ignore
    window.toggleReactQueryDevtools = () => setShowDevtools((old) => !old);

    window.__queryClient = client;
  }, [client]);

  return (
    <QueryClientProvider client={client}>
      {children}
      {showDevtools && (
        <ReactQueryDevtoolsProduction buttonPosition="bottom-right" initialIsOpen={false} />
      )}
    </QueryClientProvider>
  );
}
